import {loadProgressBar} from "axios-progress-bar";
// import 'axios-progress-bar/dist/nprogress.css';
import './ProgressBar.css';

const Config = (headers, params = null) => {
    return {
        onUploadProgress: progressEvent => {
            loadProgressBar();
        },
        onDownloadProgress: progressEvent => {
            loadProgressBar();
        },
        headers: headers,
        params: params,
    }
};

export default Config;