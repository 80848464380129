import { lazy, PureComponent, Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import RoutePath from "./components/RoutePath";
import Login from "./pages/auth/Login";
import Websocket from "./components/websocket/Websocket";
import LoadingProgress from "./components/helpers/LoadingProgress";

const CmsRoute = lazy(() => import("./pages/cms/CmsRoute"));
const MarketplaceRoute = lazy(() => import("./pages/marketplace/MarketplaceRoute"));
const GeneralRoute = lazy(() => import("./pages/general/GeneralRoute"));

export default class AppRoute extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            feature: {
                marketplace: JSON.parse(process.env.REACT_APP_FEATURE_MARKETPLACE),
                forum: JSON.parse(process.env.REACT_APP_FEATURE_FORUM),
                webinar: JSON.parse(process.env.REACT_APP_FEATURE_WEBINAR)
            }
        }
    }
    render() {
        return (
            <Websocket>
                <BrowserRouter>
                    <Suspense fallback={<LoadingProgress wholePage />}>
                        <Switch>
                            <Route exact path={RoutePath.LOGIN} component={Login} />
                            <Route path={RoutePath.CMS} component={CmsRoute} />
                            {this.state.feature.marketplace && <Route path={RoutePath.MARKETPLACE} component={MarketplaceRoute} />}
                            {(this.state.feature.forum || this.state.feature.webinar) && <Route path={RoutePath.GENERAL} component={GeneralRoute} />}
                            <Redirect to={RoutePath.LOGIN} />
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </Websocket>
        )
    }
}