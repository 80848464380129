import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min';

import AppRoute from './AppRoute';
import { requestFCMToken } from './components/helpers/FirebaseEvent';

function App() {
    requestFCMToken();
    if (process.env.REACT_APP_DEBUG_MODE === 'false') {
        window.console.log = function () {
            window.console.log = function () { }
            window.console.error = function () { }
        }
    }
    return (
        <AppRoute />
    );
}

export default App;
