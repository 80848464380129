const RoutePath = {
    LOGIN: "/",

    CMS: "/cms",
    CMS_PAGE_BUILDER: "/cms/page-builder",
    CMS_PAGE_DETAIL: "/cms/page-detail",
    CMS_PAGE_EDIT: "/cms/page-edit",
    CMS_PAGE_EDIT_ID: "/cms/page-edit/:id",

    CMS_ARTICLE: "/cms/article",
    CMS_ARTICLE_LIST: "/cms/article/list",
    CMS_ARTICLE_DETAIL: "/cms/article/detail",
    CMS_ARTICLE_TYPE: "/cms/article/type",
    CMS_ARTICLE_CATEGORY: "/cms/article/category",

    CMS_SLIDERS: "/cms/sliders",
    CMS_SLIDERS_ADD: "/cms/sliders/create",
    CMS_SLIDERS_EDIT: "/cms/sliders/edit/:id",

    CMS_ADVANCED_SLIDERS: "/cms/advanced-sliders",
    CMS_ADVANCED_SLIDERS_ADD: "/cms/advanced-sliders/create",
    CMS_ADVANCED_SLIDERS_EDIT: "/cms/advanced-sliders/edit/:id",

    CMS_MENUS: "/cms/menus",
    CMS_MENUS_ADD: "/cms/menus/create",
    CMS_MENUS_EDIT: "/cms/menus/edit/:id",

    CMS_TABS: "/cms/tabs",
    CMS_TABS_ADD: "/cms/tabs/create",
    CMS_TABS_EDIT: "/cms/tabs/edit/:id",
    CMS_CALCULATOR: "/cms/calculator",
    CMS_CALCULATOR_ADD: "/cms/calculator/create",
    CMS_CALCULATOR_EDIT: "/cms/calculator/edit/:id",


    CMS_FORMS: "/cms/forms",
    CMS_FORMS_ADD: "/cms/forms/add",
    CMS_FORMS_EDIT: "/cms/forms/edit/:id",

    CMS_NAV_BAR: "/cms/nav-bar",
    CMS_NAV_BAR_LIST: "/cms/nav-bar/list",
    CMS_NAV_BAR_ADD: "/cms/nav-bar/create",
    CMS_NAV_BAR_EDIT: "/cms/nav-bar/edit/:id",
    CMS_NAV_BAR_ASSIGN_TO_PAGES: "/cms/nav-bar/assign-to-pages",

    CMS_BREADCRUMB: "/cms/breadcrumb",
    CMS_BREADCRUMB_LIST: "/cms/breadcrumb/list-breadcrumb",

    CMS_FILE_MANAGER: "/cms/file-manager",

    CMS_SEND_BROADCAST: "/cms/send-broadcast",

    CMS_PAGE_OPTION: "/cms/page-option",
    CMS_PAGE_OPTION_PAGE_OPTION: "/cms/page-option/page-option",
    CMS_PAGE_OPTION_HOMEPAGES: "/cms/page-option/homepages",
    CMS_PAGE_OPTION_TYPOGRAPHY: "/cms/page-option/typography",
    CMS_PAGE_OPTION_SITE_IDENTITY: "/cms/page-option/site-identity",
    CMS_PAGE_OPTION_SOCIAL_MEDIA: "/cms/page-option/social-media",
    CMS_PAGE_OPTION_FOOTER_WIDGET: "/cms/page-option/footer-widget",
    CMS_PAGE_OPTION_LANGUAGE: "/cms/page-option/language",
    CMS_PAGE_OPTION_SIDEBAR_ARTICLE: "/cms/page-option/sidebar-article",
    CMS_PAGE_OPTION_CUSTOMER_DASHBOARD: "/cms/page-option/customer-dashboard",

    CMS_HEADER: "/cms/header",
    CMS_HEADER_LIST: "/cms/header/list",
    CMS_HEADER_DETAIL: "/cms/header/detail",
    CMS_HEADER_EDIT: "/cms/header/edit/:id",
    CMS_FOOTER: "/cms/footer",
    CMS_FOOTER_LIST: "/cms/footer/list",
    CMS_FOOTER_DETAIL: "/cms/footer/detail",
    CMS_FOOTER_EDIT: "/cms/footer/edit/:id",

    CMS_PRODUCT_LIST: "/cms/product-list",
    CMS_PRODUCT_CUSTOM_LIST: "/cms/product-custom-list",
    CMS_PRODUCT_CUSTOM_ADD: "/cms/product-custom/add",
    CMS_PRODUCT_CUSTOM_EDIT: "/cms/product-custom/edit/:id",
    CMS_MARKETPLACE: "/cms/marketplace",
    CMS_VOUCHER: "/cms/voucher",

    CMS_COMPANY_SETTING: "/cms/company-setting",
    CMS_MAIL_SETTING: "/cms/email-setting",
    CMS_COMPANY_DRS: "/cms/company-drs",

    CMS_AUTHENTICATION: "/cms/authentication",

    CMS_BOTTOM_BAR: "/cms/bottom-bar",

    CMS_PROFILE_SETTING: "/cms/profile-setting",
    CMS_MENU_SETTING: "/cms/setting",

    CMS_LOGIN_SETTING: "/cms/login-setting",
    CMS_SITEMAP: "/cms/sitemap",

    CMS_EMAIL: "/cms/email",
    CMS_EMAIL_HEADER: "/cms/email/header",
    CMS_EMAIL_FOOTER: "/cms/email/footer",
    CMS_EMAIL_CONTENT: "/cms/email/content",
    CMS_EMAIL_CONTENT_EDIT: "/cms/email/content-edit/:id",

    MARKETPLACE: "/marketplace",
    MARKETPLACE_SHOP_PENDING: "/marketplace/shop-pending",
    MARKETPLACE_SHOP_ALL: "/marketplace/shop-all",
    MARKETPLACE_SHOP_DETAIL: "/marketplace/shop-detail/:id",
    MARKETPLACE_SHOP_REGISTER: "/marketplace/shop-register",

    MARKETPLACE_PRODUCT_PENDING: "/marketplace/product-pending",
    MARKETPLACE_PRODUCT_ACTIVE_AND_PUBLISHED: "/marketplace/product-active-published",
    MARKETPLACE_PRODUCT_ALL: "/marketplace/product-all",
    MARKETPLACE_PRODUCT_DETAIL: "/marketplace/product-detail/:id",
    MARKETPLACE_JUBELIO_PROGRESS: "/marketplace/jubelio_progress",

    MARKETPLACE_CUSTOMER_ALL: "/marketplace/customer-all",
    MARKETPLACE_CUSTOMER_ADD: "/marketplace/customer-add",
    MARKETPLACE_CUSTOMER_DETAIL: "/marketplace/customer-detail/:id",

    MARKETPLACE_MEMBERSHIP: "/marketplace/membership",
    MARKETPLACE_MEMBERSHIP_PERIOD: "/marketplace/membership/period",
    MARKETPLACE_MEMBERSHIP_LEVEL: "/marketplace/membership/level/:period_id",
    MARKETPLACE_MEMBERSHIP_LEVEL_ADD: "/marketplace/membership/level-add/:period_id",
    MARKETPLACE_MEMBERSHIP_LEVEL_EDIT: "/marketplace/membership/level-edit/:period_id/:level_id",
    MARKETPLACE_MEMBERSHIP_LEVEL_MANAGE: "/marketplace/membership/level-manage/:period_id",
    MARKETPLACE_CATEGORY: "/marketplace/category",
    MARKETPLACE_BRAND: "/marketplace/brand",
    MARKETPLACE_SPECIFICATION: "/marketplace/specification-group",
    MARKETPLACE_SPECIFICATION_FIELD: "/marketplace/specification",

    MARKETPLACE_ORDER: "/marketplace/order",
    MARKETPLACE_PRE_ORDER: "/marketplace/pre-order",
    MARKETPLACE_REVIEW: "/marketplace/review",

    MARKETPLACE_BANK: "/marketplace/bank",
    MARKETPLACE_DISBURSEMENT: "/marketplace/disbursement",
    MARKETPLACE_DISBURSEMENT_DETAIL: "/marketplace/disbursement-detail/:id",
    MARKETPLACE_WEBINAR_DISBURSEMENT_DETAIL: "/marketplace/webinar-disbursement-detail/:id",
    MARKETPLACE_MANUAL_TRANSFER: "/marketplace/manual-transfer",
    MARKETPLACE_REFUND: "/marketplace/refund",
    MARKETPLACE_APPROVE_MIDTRANS: "/marketplace/approve-midtrans",
    MARKETPLACE_COMMISSION: "/marketplace/commission",

    MARKETPLACE_SALES_TOOLS: "/marketplace/sales-tools",
    MARKETPLACE_PROMOTION: "/marketplace/sales-tools/promotion",
    MARKETPLACE_VOUCHER: "/marketplace/sales-tools/voucher",
    MARKETPLACE_VOUCHER_VIEW: "/marketplace/sales-tools/voucher/view",
    MARKETPLACE_VOUCHER_ADD: "/marketplace/sales-tools/voucher/add",
    MARKETPLACE_VOUCHER_EDIT: "/marketplace/sales-tools/voucher/edit/:id",
    MARKETPLACE_VOUCHER_CUSTOMER: "/marketplace/sales-tools/voucher/customer/:id",
    MARKETPLACE_VOUCHER_GROUPING: "/marketplace/sales-tools/voucher/grouping",
    MARKETPLACE_VOUCHER_MANAGE: "/marketplace/sales-tools/voucher/manage",
    MARKETPLACE_VOUCHER_MANAGE_CHANCE: "/marketplace/sales-tools/voucher/manage-chance/:id",
    MARKETPLACE_SPIN_WHEEL: "/marketplace/spin-wheel/spin-wheel-setting",
    MARKETPLACE_SPIN_WHEEL_MANAGE_ITEM: "/marketplace/spin-wheel/manage-item/:id",

    MARKETPLACE_PROMOTION: "/marketplace/sales-tools/promotion/manage",
    MARKETPLACE_PROMOTION_DETAIL: "/marketplace/sales-tools/promotion/detail/:id",
    MARKETPLACE_SUBSCRIBER_LIST: "/marketplace/sales-tools/subscribers",

    MARKETPLACE_PRIVACY_POLICY: "/marketplace/privacy_policy",
    MARKETPLACE_API: "/marketplace/api",
    MARKETPLACE_GENERAL_SETTING: "/marketplace/general-setting",
    MARKETPLACE_GENERAL_SETTING_PAGE_SETTINGS: "/marketplace/general-setting/page-settings",
    MARKETPLACE_GENERAL_SETTING_PRODUCT_DETAIL: "/marketplace/general-setting/product-detail",
    MARKETPLACE_GENERAL_SETTING_CART: "/marketplace/general-setting/cart",
    MARKETPLACE_GENERAL_SETTING_CHECKOUT: "/marketplace/general-setting/checkout",
    MARKETPLACE_GENERAL_SETTING_MY_ORDERS: "/marketplace/general-setting/my-orders",
    MARKETPLACE_GENERAL_SETTING_MY_WISHLIST: "/marketplace/general-setting/my-wishlist",
    MARKETPLACE_GENERAL_SETTING_SELLER: "/marketplace/general-setting/seller",
    MARKETPLACE_GENERAL_SETTING_INVOICE: "/marketplace/general-setting/invoice",
    MARKETPLACE_GENERAL_SETTING_MIDTRANS_SETTINGS: "/marketplace/general-setting/midtrans-settings",
    MARKETPLACE_GENERAL_SETTING_PAYMENT_METHOD_SETTINGS: "/marketplace/general-setting/payment-method-settings",
    MARKETPLACE_GENERAL_SETTING_JUBELIO_SETTINGS: "/marketplace/general-setting/jubelio-settings",
    MARKETPLACE_GENERAL_SETTING_COURIER_SETTINGS: "/marketplace/general-setting/courier-settings",
    MARKETPLACE_GENERAL_SETTING_PLATFORM_SETTINGS: "/marketplace/general-setting/platform-settings",

    MARKETPLACE_AUCTION: "/marketplace/auction",
    MARKETPLACE_AUCTION_SETTINGS: "/marketplace/auction/auction-settings",
    MARKETPLACE_AUCTION_LIST: "/marketplace/auction/auction-list",
    MARKETPLACE_AUCTION_LIST_ACTIVE_AND_PUBLISHED: "/marketplace/auction/auction-list/product-active-published",
    MARKETPLACE_AUCTION_LIST_PENDING: "/marketplace/auction/auction-list/pending",
    MARKETPLACE_AUCTION_LIST_PRODUCT_DETAIL: "/marketplace/auction/auction-list/product-detail/:id",
    MARKETPLACE_AUCTION_PROMOTION: "/marketplace/auction/auction-promotion",
    MARKETPLACE_AUCTION_PROMOTION_LIST_ACTIVE: "/marketplace/auction/auction-promotion/promotion-active",
    MARKETPLACE_AUCTION_PROMOTION_LIST_REQUEST: "/marketplace/auction/auction-promotion/request",
    MARKETPLACE_AUCTION_PROMOTION_PRODUCT_DETAIL: "/marketplace/auction/auction-promotion/product-detail/:id",
    MARKETPLACE_AUCTION_BANNER: "/marketplace/auction/auction-banner",
    MARKETPLACE_AUCTION_BID_AND_RUN: "/marketplace/auction/bid-and-run",

    MARKETPLACE_CHAT: "/marketplace/chat",

    GENERAL: "/general",

    //Webinar
    GENERAL_WEBINAR_EVENT_CLASSES: "/general/webinar/event-classes",
    GENERAL_WEBINAR_EVENT_CLASSES_LIST: "/general/webinar/event-classes/list",
    GENERAL_WEBINAR_EVENT_CLASSES_CREATE: "/general/webinar/event-classes/create",
    GENERAL_WEBINAR_EVENT_CLASSES_EDIT: "/general/webinar/event-classes/edit/:id",

    GENERAL_WEBINAR_EVENT_CLASSES_APPROVAL: "/general/webinar/event-classes-approval",
    GENERAL_WEBINAR_EVENT_CLASSES_APPROVAL_LIST: "/general/webinar/event-classes-approval/list",
    GENERAL_WEBINAR_EVENT_CLASSES_APPROVAL_EDIT: "/general/webinar/event-classes-approval/edit/:id",

    GENERAL_WEBINAR_FEATURED_SPEAKER: "/general/webinar/featured-speaker",
    GENERAL_WEBINAR_FEATURED_SPEAKER_LIST: "/general/webinar/featured-speaker/list",
    GENERAL_WEBINAR_FEATURED_SPEAKER_CREATE: "/general/webinar/featured-speaker/create",
    GENERAL_WEBINAR_FEATURED_SPEAKER_EDIT: "/general/webinar/featured-speaker/edit/:id",

    GENERAL_WEBINAR_APPROVAL_SPEAKER: "/general/webinar/approval-speaker",
    GENERAL_WEBINAR_APPROVAL_SPEAKER_LIST: "/general/webinar/approval-speaker/list",
    GENERAL_WEBINAR_APPROVAL_SPEAKER_EDIT: "/general/webinar/approval-speaker/edit/:id",

    GENERAL_WEBINAR_FEATURED_BANNER: "/general/webinar/featured-banner",
    GENERAL_WEBINAR_FEATURED_BANNER_LIST: "/general/webinar/featured-banner/list",
    GENERAL_WEBINAR_FEATURED_BANNER_CREATE: "/general/webinar/featured-banner/create",
    GENERAL_WEBINAR_FEATURED_BANNER_EDIT: "/general/webinar/featured-banner/edit/:id",

    GENERAL_FORUM: "/general/forum",
    GENERAL_FORUM_CATEGORY: "/general/forum/category",
    GENERAL_FORUM_LIST: "/general/forum/list",
    GENERAL_FORUM_BLOCKED_USER: "/general/forum/blocked-user",
    GENERAL_FORUM_REPORT_COMMENTS: "/general/forum/report-comments",
    GENERAL_FORUM_WHITELIST: "/general/forum/whitelist",
    GENERAL_FORUM_BANNER: "/general/forum/banner",
}

export default RoutePath;