import React, { PureComponent } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import IsEmpty from "../../components/helpers/IsEmpty";
import Config from "../../components/axios/Config";
import RoutePath from '../../components/RoutePath';
import { Redirect } from 'react-router-dom';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});

class Login extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            errors: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (!IsEmpty(Cookie.get('token'))) {
            if (/*!IsEmpty(Cookie.get('fcm_token_tecta_admin'))*/ true) {
                this.props.history.push({
                    pathname: RoutePath.DASHBOARD
                });
            } else {
                // requestFCMToken();
            }
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        let data = new FormData(event.target);

        const user = {
            email: data.get('email'),
            password: data.get('password')
        };

        this.setState({
            submitting: true,
            errors: [],
        })

        axios.post(`${process.env.REACT_APP_BASE_API_URL}auth/login`, user, Config()).then(res => {
            console.log(res.data);
            if (res.data.data.access_token) {
                Cookie.set('token', res.data.data.access_token);
                Cookie.set('user', res.data.data.user);
                let now = new Date()
                now.setDate(now.getDate() + parseInt(process.env.REACT_APP_TOKEN_EXPIRE_DAYS))
                localStorage.setItem("expire_date", now)
                localStorage.setItem("platform", "cms")
                // initializeAuthorization();
                // requestFCMToken();

                this.props.history.go(0)

            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Wrong Credential!'
                });
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 429) {
                    this.setState({
                        errors: {
                            wrong: ["Too many attempts. Please try again in one minute."]
                        }
                    });
                } else {
                    this.setState({
                        errors: {
                            wrong: [error.response.data.message]
                        }
                    });

                }

                console.log(error);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Sorry, we have a problem right now, please try again later.'

                });

            }
        }).finally(() => {
            this.setState({ submitting: false })
        });
    }

    render() {
        return (
            <div className="container">
                {Cookie.get('token') && <Redirect to={RoutePath.CMS} />}
                <div className="login-content">
                    <div className="text-center">
                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/tecta-global-logo.png`} alt="" className="w-50" />
                    </div>
                    <div className="card shadow-graph mt-4">
                        <form className="p-4" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" name="email" required />
                            </div>
                            {this.state.errors.email && <div className="alert alert-danger p-2 mt-2 text-center" role="alert">{this.state.errors.email[0]}</div>}
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password" className="form-control" name="password" required />
                            </div>
                            {this.state.errors.wrong && <div className="alert alert-danger p-2 mt-2 text-center" role="alert">{this.state.errors.wrong[0]}</div>}
                            <div className="row">
                                <div className="col-6">
                                    {/* <label><input type="checkbox"/> Remember me</label> */}
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn bgc-0F74BD font-weight-bold text-white" disabled={this.state.submitting}>Login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;